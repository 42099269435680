exports.components = {
  "component---src-pages-cgd-dashboard-js": () => import("./../../../src/pages/cgd/dashboard.js" /* webpackChunkName: "component---src-pages-cgd-dashboard-js" */),
  "component---src-pages-cgd-forms-add-js": () => import("./../../../src/pages/cgd/forms/add.js" /* webpackChunkName: "component---src-pages-cgd-forms-add-js" */),
  "component---src-pages-cgd-forms-index-js": () => import("./../../../src/pages/cgd/forms/index.js" /* webpackChunkName: "component---src-pages-cgd-forms-index-js" */),
  "component---src-pages-cgd-index-js": () => import("./../../../src/pages/cgd/index.js" /* webpackChunkName: "component---src-pages-cgd-index-js" */),
  "component---src-pages-cgd-reports-index-js": () => import("./../../../src/pages/cgd/reports/index.js" /* webpackChunkName: "component---src-pages-cgd-reports-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */)
}

